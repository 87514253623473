import "./style.scss";

function WorkDetails({
  title,
  description,
  objectif,
  logiciel,
  date,
  cover,
  visuals,
  videoLinks,
}) {
  return (
    <div className="work-details-container">
      <div className="work-details-description">
        <div className="title-container">
          {/* <h3>{title}</h3> */}
          <h5>{description}</h5>
          <hr />
        </div>
        <div className="work-others-details-container">
          <div className="objectifs-container">
            <h4>Objectifs</h4>
            <p>{objectif}</p>
          </div>
          <div>
            <h4 className="logiciels-container">Logiciels</h4>
            <p>{logiciel}</p>
          </div>
          <div className="date-container">
            <h4>Années</h4>
            <p>{date}</p>
          </div>
        </div>
      </div>
      <div className="work-details-media">
        {/* Render cover if it exists */}
        {cover && <img src={cover} alt={`cover ${title}`} loading="lazy"/>}

        {/* Render video if it exists */}
          {videoLinks &&
          videoLinks.map((videoLink, index) => (
            <div key={index} className="video-container">
              <iframe
                title={`Video ${title + index}`}
                src={videoLink}
                allowFullScreen
                loading="lazy"
              ></iframe>
              
            </div>
          ))}

        {/* Render visuals if it exists */}
        {visuals &&
          visuals.map((visual, index) => (
            <img key={index} src={visual} alt={`Visual ${title + index}`} />
          ))}

      </div>
    </div>
  );
}

export default WorkDetails;
