import handHeart from "../../assets/img/heart.webp";

import "./style.scss";

function Contact() {
  // State (état, données)

  // Comportements

  // Render
  return (
    <>
      <div className="contact-container">
        <img src={handHeart} alt="Heart" loading="lazy"/>
        <h2>À bientôt !</h2>
        <h5>lpzleanne@gmail.com <br />06 51 77 84 20</h5>
      </div>
    </>
  );
}

export default Contact;
