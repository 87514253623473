import Home from "./pages/Home";


function App() {
  // State (état, données)

  // Comportements

  // Render
  return (
    <Home />
  );
}

export default App;
