import React, { useState } from "react";
import IconSocialMedia from "../../components/IconSocialMedia";
import Navigation from "../../containers/Navigation";
import WorksMenu from "../../containers/WorksMenu";
import Contact from "../../containers/Contact";

import face from "../../assets/img/face.webp";
import faceWink from "../../assets/img/face-wink.webp";

import "../../_settings.scss";
import "./style.scss";

function Home() {
  // State (état, données)
  // State to track the image source
  const [isFaceWink, setIsFaceWink] = useState(false);

  // Comportements
  // Event handler for mouse enter
  const handleMouseEnter = () => {
    setIsFaceWink(true);
  };

  // Event handler for mouse leave
  const handleMouseLeave = () => {
    setIsFaceWink(false);
  };

  // Render
  return (
    <>
      <header>
        <Navigation />
      </header>
      <main id="hello">
        <div className="presentation-container">
          <img
            className="face-size"
            src={isFaceWink ? faceWink : face}
            alt="face"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          />
          <div className="presentation-text-container">
            <h1>Bonjour !</h1>
            <h5>
              Je m’appelle Léanne Lopez et je suis étudiante en design
              graphique.{" "}
            </h5>
            <p>
              Curieuse, j’aime découvrir de nouvelles techniques et styles de
              création.<br />Ce
              site web a été créé par mes petites mains, pour te montrer mon
              savoir-faire. N’hésite pas à faire un tour du côté des projets et
              si tu le souhaites, et à me suivre sur différentes plateformes.
            </p>
            <IconSocialMedia />
          </div>
        </div>
        <div className="works-id" id="works">
          <hr />
        </div>
        <div className="works-container">
          <h2 className="works-title">Quoi de beau aujourd'hui ?</h2>
          <WorksMenu />
        </div>
      </main>
      <footer id="contact">
        <Contact />
      </footer>
    </>
  );
}

export default Home;
