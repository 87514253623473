import handBye from "../../assets/img/bye.webp";
import handHeart from "../../assets/img/heart2.webp";
import folder from "../../assets/img/folder.webp";
// import studies from "../../assets/img/studies.png";
import "./style.scss";

function IconNavigation() {
  // State (état, données)

  // Comportements

  // Render
  return (
    <>
        <a href="#hello" className="icon-navigation"><img src={handBye} alt="hello" /></a>
        <a href="#works" className="icon-navigation"><img src={folder} alt="Floder" /></a>
        {/* <img src={studies} alt="Studies" />; */}
        <a href="#contact" className="icon-navigation width"><img src={handHeart} alt="Hand shape of heart" /></a>
    </>
  );
}

export default IconNavigation;
