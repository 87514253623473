import React, { useState } from "react";
import worksData from "../../worksData.json";
import WorkDetails from "../../containers/WorkDetails";

import "./style.scss";

// Import your images
import handOkImage from "../../assets/img/ok.webp";
import handHeartImage from "../../assets/img/heart.webp";
import handByeImage from "../../assets/img/bye.webp";
import ladompmecover from "../../assets/img/ladom_pme_cover.webp";
import ladompmeinterior from "../../assets/img/ladom_pme_interior.webp";
import ladomactcover from "../../assets/img/ladom_act_cover.webp";
import ladomactinterior from "../../assets/img/ladom_act_interior.webp";
import ladompmeflyer from "../../assets/img/ladom_pme_flyer.webp";
import ladompmerollup from "../../assets/img/ladom_pme_rollup.webp";
import ladomactrollup from "../../assets/img/ladom_act_rollup.webp";
import ladomCard2020 from "../../assets/img/ladom_card_2020.webp";
import renardCigogne from "../../assets/img/renardcigogne_1.webp";
import renardCigogne2 from "../../assets/img/renardcigogne_2.webp";
import renardCigogne3 from "../../assets/img/renardcigogne_3.webp";
import kasa1 from "../../assets/img/kasa_1.webp";
import kasa2 from "../../assets/img/kasa_2.webp";
import kasa3 from "../../assets/img/kasa_3.webp";
import omfHome from "../../assets/img/ohmyfood-website-home.webp";
import omfRestaurant from "../../assets/img/ohmyfood-website-restaurant.webp";
import diners1 from "../../assets/img/diners_1.webp";
import diners2 from "../../assets/img/diners_2.webp";
import alice from "../../assets/img/alice.webp";
import feet from "../../assets/img/drawing_feet.webp";
import lbae1 from "../../assets/img/lbae_1.webp";
import lbae2 from "../../assets/img/lbae_2.webp";
import sophie from "../../assets/img/sophie.webp";
import tourEiffel from "../../assets/img/tour_eiffel.webp";
import pi from "../../assets/img/pixel-invisible.png";

// Map image filenames to their imports
const imageImports = {
  "assets/img/ok.webp": handOkImage,
  "assets/img/heart.webp": handHeartImage,
  "assets/img/bye.webp": handByeImage,
  "assets/img/ladom_pme_cover.webp": ladompmecover,
  "assets/img/ladom_pme_interior.webp": ladompmeinterior,
  "assets/img/ladom_act_cover.webp": ladomactcover,
  "assets/img/ladom_act_interior.webp": ladomactinterior,
  "assets/img/ladom_pme_flyer.webp": ladompmeflyer,
  "assets/img/ladom_pme_rollup.webp": ladompmerollup,
  "assets/img/ladom_act_rollup.webp": ladomactrollup,
  "assets/img/ladom_card_2020.webp": ladomCard2020,
  "assets/img/renardcigogne_1.webp": renardCigogne,
  "assets/img/renardcigogne_2.webp": renardCigogne2,
  "assets/img/renardcigogne_3.webp": renardCigogne3,
  "assets/img/kasa_1.webp": kasa1,
  "assets/img/kasa_2.webp": kasa2,
  "assets/img/kasa_3.webp": kasa3,
  "assets/img/ohmyfood-website-home.webp": omfHome,
  "assets/img/ohmyfood-website-restaurant.webp": omfRestaurant,
  "assets/img/diners_1.webp": diners1,
  "assets/img/diners_2.webp": diners2,
  "assets/img/alice.webp": alice,
  "assets/img/drawing_feet.webp":feet,
  "assets/img/lbae_1.webp": lbae1,
  "assets/img/lbae_2.webp": lbae2,
  "assets/img/sophie.webp": sophie,
  "assets/img/tour_eiffel.webp": tourEiffel,
  "assets/img/pixel-invisible.png": pi,
  // Add more image mappings as needed
};

function WorksMenu() {
  const [workDetailsVisibility, setWorkDetailsVisibility] = useState({});

  const handleTitleClick = (work) => {
    setWorkDetailsVisibility({
      ...workDetailsVisibility,
      [work.id]: !workDetailsVisibility[work.id],
    });
  };

  return (
    <>
      {worksData.works.map((work) => (
        <div key={work.id}>
          <h3
            onClick={() => handleTitleClick(work)}
            className="h3-container"
          >
            {work.title}
          </h3>
          {workDetailsVisibility[work.id] && (
            <WorkDetails
              title={work.title}
              description={work.description}
              objectif={work.objectif}
              logiciel={work.logiciel}
              date={work.date}
              cover={imageImports[work.cover] || work.cover}
              visuals={work.visuals.map((visual) => imageImports[visual] || visual)}
              videoLinks={work.videoLinks}
            />
          )}
          {workDetailsVisibility[work.id] || <hr key={`hr-${work.id}`} />}
        </div>
      ))}
    </>
  );
}

export default WorksMenu;