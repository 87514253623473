import instagram from "../../assets/img/instagram.webp";
import linkedIn from "../../assets/img/linkedIn.webp";
import vimeo from "../../assets/img/vimeo.webp";
import github from "../../assets/img/github.webp";
// import mail from "../../assets/img/mail.webp";

import "./style.scss";

function IconSocialMedia() {
  // State (état, données)

  // Comportements

  // Render
  return (
    <ul className="icon-social-container">
      <li>
        <a
          href="https://www.linkedin.com/in/léannelopez"
          target="blank"
          className="icon-social"
        >
          <img src={linkedIn} alt="logo LinkedIn" />
        </a>
      </li>
      <li>
        <a
          href="https://www.instagram.com/leanne.lpz/"
          target="blank"
          className="icon-social"
        >
          <img src={instagram} alt="logo Instagram" />
        </a>
      </li>
      <li>
        <a
          href="https://vimeo.com/user123202215"
          target="blank"
          className="icon-social"
        >
          <img src={vimeo} alt="logo Vimeo" />
        </a>
      </li>
      <li>
        <a
          href="https://github.com/LpzLeanne"
          target="blank"
          className="icon-social"
        >
          <img src={github} alt="logo GitHub" />
        </a>
      </li>
      {/* <li>
        <a
          href="mailto:lpzleanne@gmail.com"
          target="blank"
          className="icon-social"
        >
          <img src={mail} alt="logo mail" />
        </a>
      </li> */}
    </ul>
  );
}

export default IconSocialMedia;
