// import Logo from "../../components/Logo";
import IconNavigation from "../../components/IconNavigation";

import "./style.scss";

function Navigation() {
  // State (état, données)

  // Comportements

  // Render
  return (
    <>
      <div className="navigation">
        {/* <Logo /> */}
        <IconNavigation />
      </div>
      <hr />
    </>
  );
}

export default Navigation;
